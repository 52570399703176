<!-- 学科数据新增 -->
<template>
    <div class="addSubjectData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学科数据新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="学科编号">
                <el-input v-model="form.number"  placeholder="请输入编号"></el-input>
            </el-form-item>
            <el-form-item label="助记码" prop="mnemonicCode">
                <el-input v-model="form.mnemonicCode" placeholder="请输入助记码"></el-input>
            </el-form-item>
            <el-form-item label="上级学科">
                <el-input v-model="form.superior" placeholder="请输入上级学科"></el-input>
            </el-form-item>
            <el-form-item label="学科名称" prop="subjectName">
                <el-input v-model="form.subjectName" placeholder="请输入学科名称"></el-input>
            </el-form-item>
            <el-form-item label="学科简称">
                <el-input v-model="form.abbreviation" placeholder="请输入学科简称"></el-input>
            </el-form-item>
            <el-form-item label="学科全称">
                <el-input v-model="form.fullName" placeholder="请输入学科全称"></el-input>
            </el-form-item>
            <el-form-item label="状态">
               <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                   <el-option v-for="item in form.stateChoose" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学科类1">
                <!-- <el-input v-model="form.classOne" placeholder="请输入学科类1"></el-input> -->
                <el-select v-model="form.classOne" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.classOneChoose" :key="item.value"
                         :label="item.label" :value="item.value">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="学科类2">
                <!-- <el-input v-model="form.classTwo" placeholder="请输入学科类2"></el-input> -->
                <el-select v-model="form.classTwo" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.classTwoChoose" :key="item.value"
                         :label="item.label" :value="item.value">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" v-model="form.explain" class="textArea-width" placeholder="请输入说明"> 
                </el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                   number: "", //学科编号
                   mnemonicCode: "", //助记码
                   superior:"",//上级学科
                   subjectName:"",//学科名称
                   abbreviation:"",//简称 
                   fullName:"",//学科全称
                   stateChoose: [
                       {label: "可用",value: "1"},
                       {label: "停用", value: "2"}
                   ], //状态选择
                   condition:"1",//状态
                   classOne:"1",//学科类1
                   classOneChoose:[
                       {label: "考级",value: "1"},
                       {label: "培训", value: "2"},
                       {label: "其他",value: "3"}
                   ],//学科类1选择
                   classTwo:"1",//学科类2
                   classTwoChoose:[
                       {label: "少儿",value: "1"},
                       {label: "成人", value: "2"},
                       {label: "考级",value: "3"}
                   ],//学科类2选择
                   explain:""//说明
               },
               rules:{
                    subjectName:[
                        { required: true, message: '请输入学科名称', trigger: 'blur' },
                    ],
                    mnemonicCode:[
                        { required: true, message: '请输入助记码', trigger: 'blur' },
                    ]
               },
               bool:false
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
               this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        let data = this.form
                        this.$request({url: '/api/course/add', method: 'POST', data: {
                        	course_number: data.number,
                        	mcode: data.mnemonicCode,
                        	pid: data.superior,
                        	course_name: data.subjectName,
                        	course_abbreviation: data.abbreviation,
                        	course_allname: data.fullName,
                        	course_style1: data.classOne,
							course_style2: data.classTwo,
							remark: data.explain,
							status: data.condition
                        }}).then(res => {
                        	if(res.code == 1){
                        		this.$message({
                        		  message: '新增列表成功',
                        		  type: 'success'
                        		})
                        		setTimeout(() => {
                        			this.goBack()	
                        		}, 1500)
                        	}else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .addSubjectData{
        width: 100%;
        height: 100%;
    }
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
